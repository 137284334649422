import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  notification,
  Typography,
  Card,
  Row,
  Select,
  Col,
  Switch,
} from "antd";

import * as api from "../../services/api";
import { useLocalStorage, useUrlParams } from "../../hooks";

import Save from "../../components/Save";
import FileUploader from "../../components/fileUploader";

import { mapErrors } from "../../lib/helpers";

const { Title } = Typography;
const { Option } = Select;

export default function Index() {
  const [accessLevels] = useLocalStorage("user");

  const [urlParams, , setUrlParams] = useUrlParams();
  const [initialValue, setInitialValue] = useState([]);
  const [loading, setLoading] = useState(!!urlParams.username);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [roles, setRoles] = useState();
  const [supplier, setSupplier] = useState();
  const [customersList, setCustomersList] = useState();
  const [redirecting, setRedirecting] = useState(false);

  const history = useHistory();
  const [form] = Form.useForm();

  const getData = () => {
    setLoading(true);
    api
      .getUserDetail(urlParams.username)
      .then((res) => {
        setInitialValue(res.data);
        api.getSupplier(res.data.customer.code).then((res) => {
          setSupplier(res.data);
        });
        api
          .getRoles({ licenece_type: res.data.license_type.code })
          .then((res) => {
            setRoles(res.data);
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getCustomersListFun = () => {
    setLoadingCustomers(true);
    api
      .getCustomersList({ paginated: false })
      .then((res) => {
        setCustomersList(res.data);
        setLoadingCustomers(false);
      })
      .catch(() => {
        setLoadingCustomers(false);
      });
  };

  useEffect(() => {
    setInitialValue([]);
    async function makePage() {
      await getData();
    }
    async function makeCustomersList() {
      await getCustomersListFun();
    }
    if (urlParams.username) {
      makePage();
    }
    makeCustomersList();
  }, [urlParams.username]);

  const onFinish = (values) => {
    console.log("Success:", values);

    if (values.license_type === "light_license") {
      values.license_type = { code: values.license_type, name: "Light" };
    } else if (values.license_type === "full_license") {
      values.license_type = { code: values.license_type, name: "Full" };
    }
    setLoading(true);
    if (urlParams.username) {
      api
        .putUserEdit(urlParams.username, values, urlParams?.type)
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      api
        .postUserCreate(values, urlParams?.type)
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
          history.replace(
            `/admin/users/`
          );
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (redirecting) {
    return <Redirect to={redirecting} />;
  }

  const chechLoading = () => {
    // return false;
    if (!loading) {
      if (!loadingCustomers) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className="capitalize">User </Breadcrumb.Item>
        <Breadcrumb.Item className="capitalize">detail</Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title className="capitalize">User </Title>
        </Col>

        {urlParams?.type === "Customer_user" ? (
          <>
            {accessLevels.user_create_update_customer && (
              <Col>
                {urlParams?.username && (
                  <Button
                    className="mr-2"
                    onClick={() => {
                      api.getResend(urlParams?.username).then((res) => {
                        notification["success"]({
                          message: res.message,
                        });
                      });
                    }}
                    loading={loading}
                  >
                    Resend Welcome Email
                  </Button>
                )}

                {urlParams.editMode ? (
                  <Button
                    onClick={() => setUrlParams({ editMode: false })}
                    loading={loading}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    onClick={() => setUrlParams({ editMode: true })}
                    loading={loading}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            )}
          </>
        ) : (
          <>
            {accessLevels.user_create_update_supplier && (
              <Col>
                {urlParams?.username && (
                  <Button
                    className="mr-2"
                    onClick={() => {
                      api.getResend(urlParams?.username).then((res) => {
                        notification["success"]({
                          message: res.message,
                        });
                      });
                    }}
                    loading={loading}
                  >
                    Resend Welcome Email
                  </Button>
                )}

                {urlParams.editMode ? (
                  <Button
                    onClick={() => setUrlParams({ editMode: false })}
                    loading={loading}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    onClick={() => setUrlParams({ editMode: true })}
                    loading={loading}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            )}
          </>
        )}
      </Row>

      <Form
        name="basic"
        form={form}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card loading={chechLoading()}>
          {[
            {
              displayText: "Full Name",
              key: "name",
              disabled: true,
              required: true,
              type: "text",
            },
            {
              displayText: "email",
              key: "email",
              disabled: true,
              required: true,
              type: "email",
            },
            {
              displayText: "Job Title",
              key: "job_title",
              disabled: false,
              required: true,
              type: "text",
            },
            {
              displayText: "Contact Number",
              key: "contact_number",
              disabled: false,
              required: true,
              type: "text",
            },
            {
              displayText: "Commercial Contact",
              key: "is_commercial_contact",
              disabled: false,
              required: false,
              type: "switch",
            },
            {
              displayText: "emergency contact",
              key: "is_emergency_contact",
              disabled: false,
              required: false,
              type: "switch",
            },
            {
              displayText: "technical contact",
              key: "is_technical_contact",
              disabled: false,
              required: false,
              type: "switch",
            },
          ].map((el) => (
            <div key={el.key}>
              {el.type === "switch" ? (
                <Form.Item
                  label={el.displayText}
                  name={el.key}
                  initialValue={initialValue[el.key] ?? false}
                  valuePropName="checked"
                  rules={[{ required: el.required }]}
                >
                  <Switch className="mr-4" disabled={!urlParams?.editMode} />
                </Form.Item>
              ) : (
                <Form.Item
                  label={el.displayText}
                  name={el.key}
                  key={el.key}
                  initialValue={initialValue[el.key]}
                  rules={[{ required: el.required }]}
                >
                  <Input disabled={!urlParams?.editMode} />
                </Form.Item>
              )}
            </div>
          ))}
          <Form.Item
            label="Customer"
            name="customer_code"
            initialValue={initialValue?.customer?.code}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onChange={(val) => {
                if (urlParams?.type === "Supplier_user") {
                  form.setFieldsValue({ suplier_code: null });
                  api.getSupplier(val).then((res) => {
                    setSupplier(res.data);
                  });
                }
              }}
              disabled={!urlParams?.editMode}
            >
              {customersList?.map((el) => (
                <Option key={el.customer_code} value={el.customer_code}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {urlParams?.type === "Supplier_user" && (
            <Form.Item
              label="Supplier"
              name="supplier_code"
              initialValue={initialValue?.supplier?.code}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select disabled={!urlParams?.editMode}>
                {supplier?.map((el) => (
                  <Option key={el.supplier_code} value={el.supplier_code}>
                    {el.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="license type"
            name="license_type"
            initialValue={initialValue?.license_type?.code}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              // showSearch
              onChange={(val) => {
                form.setFieldsValue({ access_level: null });
                api.getRoles({ licenece_type: val }).then((res) => {
                  setRoles(res.data);
                });
              }}
              disabled={!urlParams?.editMode}
            >
              {urlParams?.type === "Customer_user" && (
                <>
                  <Option value="light_license">Light</Option>
                  <Option value="full_license">Full</Option>
                </>
              )}

              {urlParams?.type === "Supplier_user" && (
                <>
                  <Option value="super_admin">super_admin</Option>
                </>
              )}
              {urlParams?.type === "system_admin" && (
                <>
                  <Option value="system_admin">Administrator License</Option>
                </>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Access Level"
            name="access_level"
            initialValue={initialValue?.roles_permissions?.code}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled={!urlParams?.editMode}>
              {roles?.map((el) => (
                <Option key={el.id} value={el.id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <FileUploader
            name="picture"
            label="picture"
            maxCount={1}
            readonly={!urlParams?.editMode}
            action="admin/user/upload-picture-user"
            value={initialValue?.picture || null}
          />
        </Card>

        <Save loading={loading} editMode={urlParams?.editMode} />
      </Form>
    </div>
  );
}
