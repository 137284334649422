import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  Row,
  Table,
  Typography,
  notification,
  Space,
  Input,
} from "antd";
import * as api from "../../services/api";
import { useLocalStorage } from "../../hooks";

export default function Index() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [accessLevels] = useLocalStorage("user");

  const [pagination, setPagination] = useState({
    current: 1,
    paginated: true,
  });

  const columns = [
    {
      title: "Customer Reference Number",
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Customer Type",
      dataIndex: "customer_type",
      key: "customer_type",
      render: (customer_type) => customer_type?.name,
    },
    {
      title: "Contract End Date",
      dataIndex: "contract",
      key: "contract",
      render: (contract, data) => data.contract?.contract_end_date,
    },
    {
      title: "Customer Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Suspend",
          value: 0,
        },
        {
          text: "Approved",
          value: 2,
        },
      ],
      render: (status) => (status === 2 ? "Approved" : "Suspend"),
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <Link to={`/admin/customers/detail?id=${data.customer_code}`}>
            View
          </Link>
          {accessLevels.customer_hard_delete && (
            <Button
              onClick={() => {
                api
                  .softDelete({ type: "customers", id: data.customer_code })
                  .then((res) => {
                    notification.success({
                      message: "Success",
                      description: "Item deleted successfully",
                    });
                    getData();
                  });
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const getData = async () => {
    setLoading(true);
    return api.getCustomersList(pagination).then((res) => {
      setData(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, [pagination]);

  const handleTableChange = (newPagination, filters) => {
    setPagination({
      ...pagination,
      current: newPagination?.current,
      status: filters.status,
    });
  };
  return (
    <>
      <Input.Search
        className="top-page-search"
        placeholder="input search text"
        onSearch={(value) => {
          setPagination({
            ...pagination,
            current: 1,
            q: value,
          });
        }}
        enterButton
      />

      <h1>Customers</h1>
      <div>
        <Row justify="space-between" align="bottom" className="mb-4">
          <Typography.Title className="capitalize">Customers</Typography.Title>
          {accessLevels.customer_create_update && (
            <Button type="primary">
              <Link to={`/admin/customers/detail?editMode=true`}>Add New</Link>
            </Button>
          )}
        </Row>

        <Card>
          <Table
            columns={columns}
            rowKey={(record) => record.customer_code}
            dataSource={data?.data}
            pagination={{
              current: pagination?.current,
              total: data?.meta?.total,
            }}
            loading={loading}
            onChange={handleTableChange}
          />
        </Card>
      </div>
    </>
  );
}
