import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  Row,
  Table,
  Tag,
  Typography,
  notification,
  Space,
} from "antd";

import { useLocalStorage, useUrlParams } from "../../hooks";
import * as api from "../../services/api";

const { Title } = Typography;

export default function Users() {
  const [loading, setLoading] = useState(false);
  const [accessLevels] = useLocalStorage("user");

  const [data, setData] = useState();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });

  const getData = async () => {
    setLoading(true);
    return api.getRoles(pagination).then((res) => {
      setData(res);
      setLoading(false);
    });
  };

  const duplicate = (id) => {
    api.duplicateAccessLevel(id).then((res) => {});
    getData();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role Type",
      dataIndex: "role_type",
      key: "role-type",
    },
    {
      title: "Customers",
      dataIndex: "customer",
      key: "customer",
      render: (customer) => {
        if (customer?.length) {
          return (
            <>
              {customer.map((customerTag) => {
                return <Tag key={customerTag.name}>{customerTag.name}</Tag>;
              })}
            </>
          );
        } else {
          return <Tag>All Customer</Tag>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (row) => (
        <Space size="middle">
          <Link to={`/admin/access-level/detail?id=${row.id}`}>View</Link>

          {accessLevels.role_create_update && (
            <Button onClick={() => duplicate(row.id)}>Duplicate</Button>
          )}
          {accessLevels.role_create_update && (
            <Button
              onClick={() => {
                api.softDelete({ type: "roles", id: row.id }).then((res) => {
                  notification.success({
                    message: "Success",
                    description: "Item deleted successfully",
                  });
                  getData();
                });
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    getData();
  }, [pagination]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination({
      ...pagination,
      page: pagination.current,
    });
  };

  return (
    <>
      <h1>Access Level</h1>
      <div>
        <Row justify="space-between" align="bottom" className="mb-4">
          <Title className="capitalize">Access Level</Title>
          {accessLevels.role_create_update && (
            <Button type="primary">
              <Link to={`/admin/access-level/detail?editMode=true`}>
                Create Template
              </Link>
            </Button>
          )}
        </Row>
        <Card>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data?.data}
            pagination={{
              current: pagination?.current,
              total: data?.meta?.total,
            }}
            loading={loading}
            onChange={handleTableChange}
          />
        </Card>
      </div>
    </>
  );
}
