import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Col,
  Checkbox,
  AutoComplete,
  Button,
  Card,
  Row,
  Table,
  Tag,
  Typography,
  Popover,
  notification,
  Select,
  Switch,
  Breadcrumb,
} from "antd";

import Save from "../../components/Save";

import { useLocalStorage, useUrlParams } from "../../hooks";
import * as api from "../../services/api";

const { Title } = Typography;
const { Option } = Select;

function AccessLevel() {
  const [urlParams, , setUrlParams] = useUrlParams();
  const [accessLevels] = useLocalStorage("user");

  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);
  const [loginAs, setLoginAs] = useState(true);
  const [templateScope, setTemplateScope] = useState("system_wide");

  const [data, setData] = useState();
  const [customerList, setCustomerList] = useState();

  const [form] = Form.useForm();
  const history = useHistory();

  const all = [
    {
      name: "login as",
      type: ["system_admin"],
      level: [
        {
          name: "any user",
          key: "login_as",
          formName: ["permissions", "login_as"],
          // fromAdminPanel: true,
          type: ["system_admin"],
        },
      ],
    },
    {
      name: "certificates",
      type: ["customer", "supplier"],
      level: [
        {
          name: "certificate approve",
          key: "permissions.certificate_approve",
          formName: ["permissions", "certificate_approve"],
          // fromAdminPanel: true,
          type: ["customer"],
        },
        {
          name: "certificate create update",
          key: "permissions.certificate_create_update",
          formName: ["permissions", "certificate_create_update"],
          // fromAdminPanel: true,
          type: ["customer", "supplier"],
        },
        {
          name: "certificate index single",
          key: "permissions.certificate_index_single",
          formName: ["permissions", "certificate_index_single"],
          // fromAdminPanel: true,
          type: ["customer", "supplier"],
        },
        {
          name: "certificate recycleBin",
          key: "permissions.certificate_recycleBin",
          formName: ["permissions", "certificate_recycleBin"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "certificate soft delete",
          key: "permissions.certificate_soft_delete",
          formName: ["permissions", "certificate_soft_delete"],
          // fromAdminPanel: true,
          type: ["customer", "supplier"],
        },
        {
          name: "certificate trash restore",
          key: "permissions.certificate_trash_restore",
          formName: ["permissions", "certificate_trash_restore"],
          // fromAdminPanel: true,
          type: ["customer", "supplier"],
        },
      ],
    },
    {
      name: "configoration",
      type: ["customer"],
      level: [
        {
          name: "company profile",
          key: "permissions.company_profile",
          formName: ["permissions", "company_profile"],
          // fromAdminPanel: true,
          type: ["customer"],
        },
        {
          name: "dashboard index",
          key: "permissions.dashboard_index",
          formName: ["permissions", "dashboard_index"],
          // fromAdminPanel: true,
          type: ["customer"],
        },
        {
          name: "subscription index",
          key: "permissions.subscription_index",
          formName: ["permissions", "subscription_index"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
      ],
    },
    {
      name: "customer",
      type: ["system_admin"],
      level: [
        // {
        //   name: "company profile",
        //   key: "permissions.company_profile",
        //   formName: ["permissions", "company_profile"],
        //   // fromAdminPanel: true,
        //   type: ["system_admin"],
        // },
        {
          name: "customer hard delete",
          key: "permissions.customer_hard_delete",
          formName: ["permissions", "customer_hard_delete"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "customer create and update",
          key: "permissions.customer_create_update",
          formName: ["permissions", "customer_create_update"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "customer index single",
          key: "permissions.customer_index_single",
          formName: ["permissions", "customer_index_single"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        // {
        //   name: "customer show customer",
        //   key: "permissions.customer_show_customer",
        //   formName: ["permissions", "customer_show_customer"],
        //   // fromAdminPanel: false,
        //   type: ["system_admin"],
        // },
        // {
        //   name: "customer update admin",
        //   key: "permissions.customer_update_admin",
        //   formName: ["permissions", "customer_update_admin"],
        //   // fromAdminPanel: false,
        //   type: ["system_admin"],
        // },
        // {
        //   name: "customer update customer",
        //   key: "permissions.customer_update_customer",
        //   formName: ["permissions", "customer_update_customer"],
        //   // fromAdminPanel: false,
        //   type: ["system_admin"],
        // },
      ],
    },
    {
      name: "manufacture",
      type: ["customer", "supplier"],
      level: [
        {
          name: "manufacture approve",
          key: "permissions.manufacture_approve",
          formName: ["permissions", "manufacture_approve"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "manufacture create update",
          key: "permissions.manufacture_create_update",
          formName: ["permissions", "manufacture_create_update"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "manufacture index single",
          key: "permissions.manufacture_index_single",
          formName: ["permissions", "manufacture_index_single"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "manufacture soft delete",
          key: "permissions.manufacture_soft_delete",
          formName: ["permissions", "manufacture_soft_delete"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "manufacture submit",
          key: "permissions.manufacture_submit",
          formName: ["permissions", "manufacture_submit"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
      ],
    },
    {
      name: "policy",
      type: ["customer", "supplier"],
      level: [
        {
          name: "policy create",
          key: "permissions.policy_create",
          formName: ["permissions", "policy_create"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "policy index",
          key: "permissions.policy_index_single",
          formName: ["permissions", "policy_index_single"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "policy recycle bin",
          key: "permissions.policy_recycle_bin",
          formName: ["permissions", "policy_recycle_bin"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "policy soft delete",
          key: "permissions.policy_soft_delete",
          formName: ["permissions", "policy_soft_delete"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "policy trash restore",
          key: "permissions.policy_trash_restore",
          formName: ["permissions", "policy_trash_restore"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
      ],
    },
    {
      name: "product",
      type: ["customer", "supplier"],
      level: [
        {
          name: "product approve",
          key: "permissions.product_approve",
          formName: ["permissions", "product_approve"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "product create update",
          key: "permissions.product_create_update",
          formName: ["permissions", "product_create_update"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "product index single",
          key: "permissions.product_index_single",
          formName: ["permissions", "product_index_single"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "product new version",
          key: "permissions.product_new_version",
          formName: ["permissions", "product_new_version"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "product recycleBin",
          key: "permissions.product_recycleBin",
          formName: ["permissions", "product_recycleBin"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "product share",
          key: "permissions.product_share",
          formName: ["permissions", "product_share"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "product soft delete",
          key: "permissions.product_soft_delete",
          formName: ["permissions", "product_soft_delete"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "product spec update",
          key: "permissions.product_spec_update",
          formName: ["permissions", "product_spec_update"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "product submit",
          key: "permissions.product_submit",
          formName: ["permissions", "product_submit"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
        {
          name: "product trash restore",
          key: "permissions.product_trash_restore",
          formName: ["permissions", "product_trash_restore"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "public link index delete",
          key: "permissions.public_link_index_delete",
          formName: ["permissions", "public_link_index_delete"],
          // fromAdminPanel: false,
          type: ["customer", "supplier"],
        },
      ],
    },

    {
      name: "supplier",
      type: ["customer"],
      level: [
        {
          name: "supplier approve",
          key: "permissions.supplier_approve",
          formName: ["permissions", "supplier_approve"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "supplier create update",
          key: "permissions.supplier_create_update",
          formName: ["permissions", "supplier_create_update"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "supplier index single",
          key: "permissions.supplier_index_single",
          formName: ["permissions", "supplier_index_single"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "supplier soft delete",
          key: "permissions.supplier_soft_delete",
          formName: ["permissions", "supplier_soft_delete"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
        {
          name: "supplier submit",
          key: "permissions.supplier_submit",
          formName: ["permissions", "supplier_submit"],
          // fromAdminPanel: false,
          type: ["customer"],
        },
      ],
    },
    {
      name: "keywords",
      type: ["system_admin"],
      level: [
        {
          name: "keyword index single",
          key: "permissions.keyword_index_single",
          formName: ["permissions", "keyword_index_single"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "keyword create update",
          key: "permissions.keyword_create_update",
          formName: ["permissions", "keyword_create_update"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "keyword hard delete",
          key: "permissions.keyword_hard_delete",
          formName: ["permissions", "keyword_hard_delete"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
      ],
    },
    {
      name: "setting",
      type: ["system_admin"],
      level: [
        {
          name: "setting index single",
          key: "permissions.setting_index_single",
          formName: ["permissions", "setting_index_single"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "setting create update",
          key: "permissions.setting_create_update",
          formName: ["permissions", "setting_create_update"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        // {
        //   name: "setting hard delete",
        //   key: "permissions.setting_hard_delete",
        //   formName: ["permissions", "setting_hard_delete"],
        //   // fromAdminPanel: false,
        //   type: ["system_admin"],
        // },
      ],
    },
    {
      name: "audit",
      type: ["system_admin"],
      level: [
        {
          name: "audit log index show",
          key: "permissions.audit_log_index_show",
          formName: ["permissions", "audit_log_index_show"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
      ],
    },
    {
      name: "roles",
      type: ["system_admin"],
      level: [
        {
          name: "role index single",
          key: "permissions.role_index_single",
          formName: ["permissions", "role_index_single"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "role create update",
          key: "permissions.role_create_update",
          formName: ["permissions", "role_create_update"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "role hard delete hard restore recycleBin admin",
          key: "permissions.role_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "role_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "role soft delete",
          key: "permissions.role_soft_delete",
          formName: ["permissions", "role_soft_delete"],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
      ],
    },
    {
      name: "user",
      type: ["customer", "system_admin"],
      level: [
        {
          name: "user change status customer",
          key: "permissions.user_change_status_customer",
          formName: ["permissions", "user_change_status_customer"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        {
          name: "user change status supplier",
          key: "permissions.user_change_status_supplier",
          formName: ["permissions", "user_change_status_supplier"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        {
          name: "user create update customer",
          key: "permissions.user_create_update_customer",
          formName: ["permissions", "user_create_update_customer"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        {
          name: "user create update supplier",
          key: "permissions.user_create_update_supplier",
          formName: ["permissions", "user_create_update_supplier"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        {
          name: "user index single customer",
          key: "permissions.user_index_single_customer",
          formName: ["permissions", "user_index_single_customer"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        {
          name: "user index single supplier",
          key: "permissions.user_index_single_supplier",
          formName: ["permissions", "user_index_single_supplier"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        // {
        //   name: "user recycleBin customer",
        //   key: "permissions.user_recycleBin_customer",
        //   formName: ["permissions", "user_recycleBin_customer"],
        //   // fromAdminPanel: false,
        //   type: ["customer", "system_admin"],
        // },
        // {
        //   name: "user recycleBin supplier",
        //   key: "permissions.user_recycleBin_supplier",
        //   formName: ["permissions", "user_recycleBin_supplier"],
        //   // fromAdminPanel: false,
        //   type: ["customer", "system_admin"],
        // },
        {
          name: "user soft delete customer",
          key: "permissions.user_soft_delete_customer",
          formName: ["permissions", "user_soft_delete_customer"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        {
          name: "user soft delete supplier",
          key: "permissions.user_soft_delete_supplier",
          formName: ["permissions", "user_soft_delete_supplier"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        {
          name: "user trash restore customer",
          key: "permissions.user_trash_restore_customer",
          formName: ["permissions", "user_trash_restore_customer"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
        {
          name: "user trash restore supplier",
          key: "permissions.user_trash_restore_supplier",
          formName: ["permissions", "user_trash_restore_supplier"],
          // fromAdminPanel: false,
          type: ["customer", "system_admin"],
        },
      ],
    },
    {
      name: "Admin User",
      type: ["system_admin"],
      level: [
        {
          name: "get index & single admin users",
          key: "permissions.user_index_single_admin",
          formName: ["permissions", "user_index_single_admin"],
          type: ["system_admin"],
        },
        {
          name: "Create & update admin users",
          key: "permissions.user_create_update_admin",
          formName: ["permissions", "user_create_update_admin"],
          type: ["system_admin"],
        },
        {
          name: "soft delete admin users",
          key: "permissions.user_soft_delete_admin",
          formName: ["permissions", "user_soft_delete_admin"],
          type: ["system_admin"],
        },
      ],
    },
    {
      name: "Admin Recycle Bin",
      type: ["system_admin"],
      level: [
        {
          name: "customer",
          key: "permissions.customer_soft_delete_restore_recycleBin_admin",
          formName: [
            "permissions",
            "customer_soft_delete_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "certificate",
          key: "permissions.certificate_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "certificate_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "manufacture",
          key: "permissions.manufacture_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "manufacture_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "policy",
          key: "permissions.policy_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "policy_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "product",
          key: "permissions.product_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "product_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "supplier",
          key: "permissions.supplier_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "supplier_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "customer user",
          key: "permissions.customer_user_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "customer_user_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "supplier user",
          key: "permissions.supplier_user_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "supplier_user_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
        {
          name: "admin user",
          key: "permissions.admin_user_hard_delete_hard_restore_recycleBin_admin",
          formName: [
            "permissions",
            "admin_user_hard_delete_hard_restore_recycleBin_admin",
          ],
          // fromAdminPanel: false,
          type: ["system_admin"],
        },
      ],
    },
  ];

  const getData = async () => {
    return api
      .getRoleDetail(urlParams.id)
      .then((res) => {
        setData(res.data);
        setRole(res.data.role_type);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("getData -`> err", err);
      });
  };

  const getCustomerList = async () => {
    console.log(
      "🚀 ~ file: AccessLevelDetail.js ~ line 593 ~ getCustomerList ~ getCustomerList"
    );
    return api
      .getCustomersList(null)
      .then((res) => {
        console.log(
          "🚀 ~ file: AccessLevelDetail.js ~ line 583 ~ .then ~ res",
          res
        );
        setCustomerList(res.data);
      })
      .catch((err) => {
        console.log("getData -> err", err);
      });
  };

  useEffect(() => {
    if (urlParams.id) {
      (async () => {
        setLoading(true);
        await getData();
      })();
    } else {
      setLoading(false);
    }
    getCustomerList();
  }, []);

  function handleChange(value) {
    setRole(value);
  }

  const onChangeLoginAs = () => {
    setLoginAs(!loginAs);
  };

  const onFinish = (values) => {
    setLoading(true);
    if (urlParams.id) {
      api
        .roleUpdate(urlParams.id, values)
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      api
        .roleStore(values)
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
          setUrlParams({ id: res.data.id });
          history.replace(
            `/admin/access-level/detail?id=${res.data.id}&editMode=true`
          );
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item className="capitalize">Access Level</Breadcrumb.Item>
        <Breadcrumb.Item className="capitalize">detail</Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title className="capitalize">Access Level </Title>
        </Col>
        {accessLevels.role_create_update && (
          <Col>
            {urlParams.editMode ? (
              <Button onClick={() => setUrlParams({ editMode: false })}>
                Cancel
              </Button>
            ) : (
              <Button onClick={() => setUrlParams({ editMode: true })}>
                Edit
              </Button>
            )}
          </Col>
        )}
      </Row>

      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Card loading={loading}>
          <Form.Item
            label="Template Name"
            name="name"
            labelCol={{ span: 6 }}
            initialValue={data?.name}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={!urlParams?.editMode} />
          </Form.Item>

          <Form.Item
            label="role type"
            name="role_type"
            labelCol={{ span: 6 }}
            initialValue={data?.role_type}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              // showSearch
              onChange={handleChange}
              disabled={!urlParams?.editMode}
            >
              <Option value="customer">Customer</Option>
              <Option value="supplier">Supplier</Option>
              <Option value="system_admin">Administrator</Option>
            </Select>
          </Form.Item>

          {role === "customer" && (
            <>
              <Form.Item
                label="For All Customer"
                name="role_from"
                initialValue={data?.role_from}
                labelCol={{ span: 6 }}
              >
                <Select
                  disabled={!urlParams?.editMode}
                  onChange={(val) => {
                    setTemplateScope(val);
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Option value="system_wide">System wide</Option>
                  <Option value="specific_customer">Specific Customer</Option>
                </Select>
              </Form.Item>

              {templateScope === "specific_customer" && (
                <Form.Item
                  label="Customer"
                  name="customer"
                  initialValue={[]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Select mode="multiple" disabled={!urlParams?.editMode}>
                    {customerList.map((customer) => (
                      <Option
                        key={customer.customer_code}
                        value={customer.customer_code}
                      >
                        {customer.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                label="License Type"
                name="license_type"
                rules={[
                  {
                    required: true,
                  },
                ]}
                labelCol={{ span: 6 }}
                initialValue={data?.license_type}
              >
                <Select disabled={!urlParams?.editMode}>
                  <Option value="light_license">Light</Option>
                  <Option value="full_license">Full</Option>
                </Select>
              </Form.Item>
            </>
          )}

          {role === "supplier" && (
            <>
              <Form.Item
                label="License Type"
                name="license_type"
                initialValue={data?.license_type ?? "supplier"}
                rules={[
                  {
                    required: true,
                  },
                ]}
                labelCol={{ span: 6 }}
              >
                <Select disabled={!urlParams?.editMode}>
                  <Option value="supplier">supplier License</Option>
                </Select>
              </Form.Item>
            </>
          )}

          {role === "system_admin" && (
            <>
              <Form.Item
                label="License Type"
                name="license_type"
                initialValue={data?.license_type ?? "system_admin"}
                rules={[
                  {
                    required: true,
                  },
                ]}
                labelCol={{ span: 6 }}
              >
                <Select disabled={!urlParams?.editMode}>
                  <Option value="system_admin">Administrator License</Option>
                </Select>
              </Form.Item>
            </>
          )}

          <>
            {all.map((Levelgroup) => (
              <span key={Levelgroup.name}>
                {Levelgroup.type?.indexOf(role) > -1 && (
                  <div className="flex flex-wrap mb-4 items-center">
                    <h2 className="w-1/4 mb-4 text-right pr-2 mt-1">
                      {Levelgroup.name} :
                    </h2>

                    <div className="w-3/4 flex flex-wrap border-t  border-indigo-500 pt-4">
                      {Levelgroup.level.map((Level) => (
                        <span key={Level.key} className="mb-0 w-1/3">
                          {Level.type?.indexOf(role) > -1 && (
                            <>
                              <Form.Item
                                labelCol={{ span: 18 }}
                                label={Level.name}
                                name={Level.formName}
                                initialValue={
                                  data?.[Level.formName[0]][
                                    Level.formName[1]
                                  ] ?? false
                                }
                                valuePropName="checked"
                              >
                                <Switch
                                  className="mr-4"
                                  disabled={!urlParams?.editMode}
                                />
                              </Form.Item>
                            </>
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </span>
            ))}
          </>
        </Card>

        <Save loading={loading} editMode={urlParams?.editMode} />
      </Form>
    </>
  );
}

export default AccessLevel;
