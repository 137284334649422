import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  notification,
  Typography,
  Card,
  Row,
  Col,
} from "antd";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import * as api from "../../services/api";
import { useUrlParams } from "../../hooks";

import Save from "../../components/Save";

const { Title } = Typography;

export default function Index() {
  const [urlParams, , setUrlParams] = useUrlParams();
  const [initialValue, setInitialValue] = useState([]);
  const [loading, setLoading] = useState(!!urlParams.id);

  const getData = () => {
    setLoading(true);
    api.getSettings({ type: "TERMS_AND_CONDITION" }).then((res) => {
      setInitialValue(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setInitialValue([]);
    // async function makePage() {
    //   await getData();
    // }
    getData();
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    api
      .putSettings({ type: "TERMS_AND_CONDITION", ...values })
      .then((res) => {
        setLoading(false);
        notification["success"]({
          message: res.message,
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className="capitalize">
          Terms of use and Policy
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row justify="space-between" align="middle">
        <Col>
          <Title className="capitalize">Terms of use and Policy</Title>
        </Col>
        <Col>
          {urlParams.editMode ? (
            <Button onClick={() => setUrlParams({ editMode: false })}>
              Cancel
            </Button>
          ) : (
            <Button onClick={() => setUrlParams({ editMode: true })}>
              Edit
            </Button>
          )}
        </Col>
      </Row>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card loading={loading}>
          <Form.Item
            label="Terms"
            name="TERMS_AND_CONDITION"
            initialValue={initialValue.TERMS_AND_CONDITION}
            rules={[{ required: true }]}
          >
            <ReactQuill placeholder="TERMS AND CONDITION" />
          </Form.Item>
        </Card>

        <Save loading={loading} editMode={urlParams?.editMode} />
      </Form>
    </div>
  );
}
