let apiBaseUrl = "";
let customerPanelUrl = "";
if (process.env.NODE_ENV === "development") {
  apiBaseUrl = "http://localhost:7000/api";
  customerPanelUrl = "http://localhost:3000/";
} else {
  apiBaseUrl = "https://admin.specready.co.uk/api";
  customerPanelUrl = "https://admin.specready.co.uk/";
}

const Config = {
  apiBaseUrl,
  storage: "http://localhost:8000/storage",
  customerPanelUrl,
};

export default Config;
