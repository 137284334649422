import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  notification,
  Typography,
  Card,
  Row,
  Col,
} from "antd";

import * as api from "../../services/api";
import { useUrlParams } from "../../hooks";

import Save from "../../components/Save";

const { Title } = Typography;

export default function Index() {
  const [urlParams, , setUrlParams] = useUrlParams();
  const [initialValue, setInitialValue] = useState([]);
  const [loading, setLoading] = useState(!!urlParams.id);

  const getData = () => {
    setLoading(true);
    api
      .getSettings({ type: "MAIL_SMTP_DRIVER" })
      .then((res) => {
        setInitialValue(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setInitialValue([]);
    async function makePage() {
      await getData();
    }
    makePage();
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    api.putSettings({ type: "MAIL_SMTP_DRIVER", ...values }).then((res) => {
      setLoading(false);
      notification["success"]({
        message: res.message,
      });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className="capitalize">Setting</Breadcrumb.Item>
        <Breadcrumb.Item className="capitalize">SMTP</Breadcrumb.Item>
      </Breadcrumb>
      <Row justify="space-between" align="middle">
        <Col>
          <Title className="capitalize">SMTP</Title>
        </Col>
        <Col>
          {urlParams.editMode ? (
            <Button onClick={() => setUrlParams({ editMode: false })}>
              Cancel
            </Button>
          ) : (
            <Button onClick={() => setUrlParams({ editMode: true })}>
              Edit
            </Button>
          )}
        </Col>
      </Row>
      <Form
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card loading={loading}>
          {[
            {
              displayText: "MAIL_FROM_NAME",
              required: true,
              key: "MAIL_FROM_NAME",
            },
            {
              displayText: "MAIL_FROM_ADDRESS",
              required: true,
              key: "MAIL_FROM_ADDRESS",
            },
            {
              displayText: "MAIL_ENCRYPTION",
              required: true,
              key: "MAIL_ENCRYPTION",
            },
            {
              displayText: "MAIL_PASSWORD",
              required: true,
              key: "MAIL_PASSWORD",
            },
            {
              displayText: "MAIL_USERNAME",
              required: true,
              key: "MAIL_USERNAME",
            },
            {
              displayText: "MAIL_PORT",
              required: true,
              key: "MAIL_PORT",
            },
            {
              displayText: "MAIL_HOST",
              required: true,
              key: "MAIL_HOST",
            },
            {
              displayText: "MAIL_MAILER",
              required: true,
              key: "MAIL_MAILER",
            },
          ].map((el) => (
            <Form.Item
              label={el.displayText}
              name={el.key}
              initialValue={initialValue[el.key]}
              rules={[{ required: el.required }]}
            >
              <Input disabled={!urlParams?.editMode} />
            </Form.Item>
          ))}
        </Card>

        <Save loading={loading} editMode={urlParams?.editMode} />
      </Form>
    </div>
  );
}
