import { useEffect, useState } from "react";
import moment from "moment";

import { Redirect, useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  notification,
  Typography,
  Card,
  Row,
  Select,
  Col,
  DatePicker,
  InputNumber,
} from "antd";

import * as api from "../../services/api";
import { useLocalStorage, useUrlParams } from "../../hooks";
import FileUploader from "../../components/fileUploader";

import Save from "../../components/Save";

import { mapErrors } from "../../lib/helpers";
import { Option } from "antd/lib/mentions";

const { Title } = Typography;

const fields = [
  {
    displayText: "General",
    key: "General",
    formName: ["General"],
    type: "title",
  },
  {
    displayText: "Customer Reference Number",
    key: "reference_number",
    formName: ["reference_number"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Customer Name",
    key: "name",
    formName: ["name"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Corresponding Address",
    key: "address",
    formName: ["address"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Signatory Name",
    key: "signatury_name",
    formName: ["signatury_name"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Signatory Title",
    key: "signatury_title",
    formName: ["signatury_title"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Signatory Email",
    key: "signatury_email",
    formName: ["signatury_email"],
    type: "email",
    rulesType: "email",
    required: true,
  },
  {
    displayText: "Signatory Phone",
    key: "signatury_phone",
    formName: ["signatury_phone"],
    type: "string",
    rulesType: "string",
    required: true,
    disabled: true,
  },
  {
    displayText: "Technical Contact Name",
    key: "technical_name",
    formName: ["technical_name"],
    type: "string",
    rulesType: "string",
  },
  {
    displayText: "Technical Contact Title",
    key: "technical_title",
    formName: ["technical_title"],
    type: "string",
    rulesType: "string",
  },
  {
    displayText: "Technical Contact Email",
    key: "technical_email",
    formName: ["technical_email"],
    type: "string",
    rulesType: "email",
  },
  {
    displayText: "Technical Contact Phone",
    key: "technical_phone",
    formName: ["technical_phone"],
    type: "string",
    rulesType: "string",
  },
  {
    displayText: "Billing Address",
    key: "billing_address",
    formName: ["billing_address"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Billing Contact Name",
    key: "billing_name",
    formName: ["billing_name"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Billing Contact Title",
    key: "billing_title",
    formName: ["billing_title"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Billing Contact Email",
    key: "billing_email",
    formName: ["billing_email"],
    type: "string",
    rulesType: "email",
    required: true,
  },
  {
    displayText: "Billing Contact Phone",
    key: "billing_phone",
    formName: ["billing_phone"],
    type: "string",
    rulesType: "string",
    required: true,
  },
  {
    displayText: "Emergency Contact Name",
    key: "emergency_name",
    formName: ["emergency_name"],
    type: "string",
    rulesType: "string",
  },
  {
    displayText: "Emergency Contact Title",
    key: "emergency_title",
    formName: ["emergency_title"],
    type: "string",
    rulesType: "string",
  },
  {
    displayText: "Emergency Contact Email",
    key: "emergency_email",
    formName: ["emergency_email"],
    type: "string",
    rulesType: "email",
  },
  {
    displayText: "Emergency Contact Phone",
    key: "emergency_phone",
    formName: ["emergency_phone"],
    type: "string",
    rulesType: "string",
  },
  {
    displayText: "Customer Type",
    key: "customer_type",
    formName: ["customer_type"],
    type: "customSelect",
    required: true,
  },

  {
    displayText: "Customer Website",
    key: "CustomerWebsite",
    formName: ["website"],
    type: "string",
  },

  {
    displayText: "review date",
    key: "reviewDates",
    formName: ["review_dates"],
    type: "InputNumber",
    rulesType: "number",
  },

  {
    displayText: "SAQ file",
    type: "file",
    key: "saqfile",
    formName: ["saq_file"],
    required: true,
  },

  {
    displayText: "Logo",
    type: "file",
    key: "logo",
    formName: ["logo"],
    required: true,
  },

  {
    displayText: "Service",
    key: "service",
    formName: ["service"],
    type: "title",
  },

  {
    displayText: "Service Type",
    key: "service_type",
    formName: ["service_type"],
    type: "string",
    rulesType: "string",
  },
  {
    displayText: "Full Licence Customer",
    key: "full_license_users_count",
    formName: ["full_license_users_count"],
    type: "InputNumber",
    rulesType: "number",
    required: true,
  },
  {
    displayText: "Light Licence Customer",
    key: "light_license_users_count",
    formName: ["light_license_users_count"],
    type: "InputNumber",
    rulesType: "number",
    required: true,
  },
  {
    displayText: "Spec",
    key: "products_count",
    formName: ["products_count"],
    type: "InputNumber",
    rulesType: "number",
    required: true,
  },
  {
    displayText: "Trial Duration",
    key: "trial_duration",
    formName: ["trial_duration"],
    type: "string",
    rulesType: "string",
  },
  {
    displayText: "Supplier",
    key: "supplier_count",
    formName: ["supplier_count"],
    type: "InputNumber",
    rulesType: "number",
    required: true,
  },
  {
    displayText: "Supplier Users",
    key: "user_supplier_count",
    formName: ["user_supplier_count"],
    type: "InputNumber",
    rulesType: "number",
    required: true,
  },
  {
    displayText: "Support",
    key: "support",
    formName: ["support"],
    type: "string",
    rulesType: "string",
  },

  {
    displayText: "Contract",
    key: "Contract",
    formName: ["Contract"],
    type: "title",
  },

  {
    displayText: "VAT Number",
    type: "string",
    rulesType: "string",
    key: "vat_number",
    formName: ["contract", "vat_number"],
  },
  {
    displayText: "Contract Start Date",
    type: "DatePicker",
    rulesType: "date",
    key: "contract_start_date",
    formName: ["contract", "contract_start_date"],
  },
  {
    displayText: "Template Fee",
    type: "string",
    rulesType: "string",
    key: "template_fee",
    formName: ["contract", "template_fee"],
  },
  {
    displayText: "Contract End Date",
    type: "DatePicker",
    rulesType: "date",
    key: "contract_end_date",
    formName: ["contract", "contract_end_date"],
  },
  {
    displayText: "Setup Fee",
    type: "string",
    rulesType: "string",
    key: "setup_fee",
    formName: ["contract", "setup_fee"],
  },
  {
    displayText: "Recurring Fee",
    type: "string",
    rulesType: "string",
    key: "recurring_fee",
    formName: ["contract", "recurring_fee"],
  },
  {
    displayText: "Full Licence",
    type: "string",
    rulesType: "string",
    key: "full_licence",
    formName: ["contract", "full_licence"],
  },
  {
    displayText: "Recurring Duration",
    type: "string",
    rulesType: "string",
    key: "recurring_duration",
    formName: ["contract", "recurring_duration"],
  },
  {
    displayText: "Light Licence",
    type: "string",
    rulesType: "string",
    key: "light_licence",
    formName: ["contract", "light_licence"],
  },
  {
    displayText: "Supplier Licence",
    type: "string",
    rulesType: "string",
    key: "supplier_licence",
    formName: ["contract", "supplier_licence"],
  },
  {
    displayText: "Product Licence",
    type: "string",
    rulesType: "string",
    key: "product_licence",
    formName: ["contract", "product_licence"],
  },
  {
    displayText: "Contract file",
    type: "file",
    key: "product_licence",
    formName: ["contract", "contract_file"],
  },
  {
    displayText: "status",
    type: "select",
    key: "status",
    formName: ["status"],
    options: [
      {
        label: "Suspend",
        value: 0,
      },
      {
        label: "Approved",
        value: 2,
      },
    ],
  },
  {
    displayText: "admin note",
    type: "textarea",
    key: "admin_note",
    formName: ["admin_note"],
    required: false,
    rulesType: "string",
  },
];

export default function Index() {
  const [accessLevels] = useLocalStorage("user");

  const [urlParams, , setUrlParams] = useUrlParams();
  const [initialValue, setInitialValue] = useState([]);
  const [loading, setLoading] = useState(!!urlParams.id);
  const [redirecting, setRedirecting] = useState(false);
  const [typeOptions, setTypeOptions] = useState([
    {
      label: "Agent",
      value: 0,
    },
    {
      label: "Manufacture",
      value: 1,
    },
    {
      label: "Warehouse",
      value: 2,
    },
    {
      label: "Distributor",
      value: 3,
    },
    {
      label: "Retail",
      value: 4,
    },
    {
      label: "Wholesale",
      value: 5,
    },
  ]);

  const [form] = Form.useForm();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const getData = () => {
    setLoading(true);
    api
      .getCustomersEdit(urlParams.id)
      .then((res) => {
        setInitialValue(res.data);
        if (res.data.customer_type.code === -1) {
          setTypeOptions([
            {
              label: "Agent",
              value: 0,
            },
            {
              label: "Manufacture",
              value: 1,
            },
            {
              label: "Warehouse",
              value: 2,
            },
            {
              label: "Distributor",
              value: 3,
            },
            {
              label: "Retail",
              value: 4,
            },
            {
              label: "Wholesale",
              value: 5,
            },
            {
              label: res.data.customer_type.name,
              value: res.data.customer_type.code,
            },
          ]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setInitialValue([]);
    async function makePage() {
      await getData();
    }
    if (urlParams.id) {
      makePage();
    }
  }, [urlParams.id]);

  const onFinish = (values) => {
    console.log("Success:", values);

    if (values.customer_type !== -1) {
      values.customer_type = {
        code: values.customer_type,
        name: typeOptions[values.customer_type].label,
      };
    } else {
      values.customer_type = {
        code: values.customer_type,
        name: typeOptions[typeOptions.length - 1].label,
      };
    }

    setLoading(true);
    if (urlParams.id) {
      api
        .putCustomersEdit(urlParams.id, values)
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      api
        .postCustomersCreate(values)
        .then((res) => {
          setLoading(false);
          notification["success"]({
            message: res.message,
          });
          history.replace(
            `/admin/customers/detail?id=${res.data.customer_code}`
          );
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (redirecting) {
    return <Redirect to={redirecting} />;
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item className="capitalize">Customers </Breadcrumb.Item>
        <Breadcrumb.Item className="capitalize">detail</Breadcrumb.Item>
      </Breadcrumb>

      <Row justify="space-between" align="middle">
        <Col>
          <Title className="capitalize">Customers </Title>
        </Col>
        {accessLevels.customer_create_update && (
          <Col>
            {urlParams.editMode ? (
              <Button onClick={() => setUrlParams({ editMode: false })}>
                Cancel
              </Button>
            ) : (
              <Button onClick={() => setUrlParams({ editMode: true })}>
                Edit
              </Button>
            )}
          </Col>
        )}
      </Row>

      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card loading={loading}>
          {fields.map((el) => (
            <div key={el.key}>
              {el.type === "title" ? (
                <Title className="ant-col-6 text-right">{el.displayText}</Title>
              ) : el.type === "file" ? (
                <FileUploader
                  name={el.formName}
                  label={el.displayText}
                  maxCount={1}
                  readonly={!urlParams?.editMode}
                  action="admin/customers/upload"
                  rules={[
                    { required: el.required },
                  ]}
                  value={initialValue[el.formName] || null}
                />
              ) : el.type === "DatePicker" ? (
                <Form.Item
                  label={el.displayText}
                  name={el.formName}
                  initialValue={
                    initialValue[el.formName[0]]?.[el.formName[1]]
                      ? moment(
                          initialValue[el.formName[0]]?.[el.formName[1]],
                          "DD/MM/YYYY"
                        )
                      : null
                  }
                  rules={[
                    { required: el.required },
                    {
                      type: el.rulesType,
                    },
                  ]}
                >
                  <DatePicker
                    disabled={!urlParams?.editMode}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              ) : el.type === "select" ? (
                <Form.Item
                  label={el.displayText}
                  name={el.formName}
                  rules={[{ required: true }]}
                  initialValue={initialValue[el.formName[0]] || 2}
                >
                  <Select
                    disabled={!urlParams?.editMode}
                    options={el.options}
                  />
                </Form.Item>
              ) : el.type === "customSelect" ? (
                <>
                  <Form.Item
                    label={el.displayText}
                    name={el.formName}
                    rules={[{ required: true }]}
                    initialValue={
                      initialValue?.customer_type?.code !== undefined
                        ? Number(initialValue?.customer_type?.code)
                        : null
                    }
                  >
                    <Select
                      showSearch
                      onSearch={(value) => {
                        if (value) {
                          setTypeOptions([
                            {
                              label: "Agent",
                              value: 0,
                            },
                            {
                              label: "Manufacture",
                              value: 1,
                            },
                            {
                              label: "Warehouse",
                              value: 2,
                            },
                            {
                              label: "Distributor",
                              value: 3,
                            },
                            {
                              label: "Retail",
                              value: 4,
                            },
                            {
                              label: "Wholesale",
                              value: 5,
                            },
                            {
                              label: value,
                              value: -1,
                            },
                          ]);
                        } else {
                          setTypeOptions([
                            {
                              label: "Agent",
                              value: 0,
                            },
                            {
                              label: "Manufacture",
                              value: 1,
                            },
                            {
                              label: "Warehouse",
                              value: 2,
                            },
                            {
                              label: "Distributor",
                              value: 3,
                            },
                            {
                              label: "Retail",
                              value: 4,
                            },
                            {
                              label: "Wholesale",
                              value: 5,
                            },
                          ]);
                        }
                      }}
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      optionFilterProp="children"
                      disabled={!urlParams?.editMode}
                      // options={typeOptions}
                    >
                      {typeOptions.map((op) => (
                        <Option key={op.value} value={op.value}>
                          {op.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  label={el.displayText}
                  name={el.formName}
                  initialValue={
                    el.formName[1] !== undefined
                      ? el.InputNumber
                        ? Number(initialValue[el.formName[0]]?.[el.formName[1]])
                        : initialValue[el.formName[0]]?.[el.formName[1]]
                      : initialValue[el.formName[0]]
                  }
                  rules={[
                    { required: el.required },
                    {
                      type: el.rulesType,
                    },
                  ]}
                >
                  {el.type === "textarea" ? (
                    <Input.TextArea disabled={!urlParams?.editMode} />
                  ) : el.type === "InputNumber" ? (
                    <InputNumber disabled={!urlParams?.editMode} />
                  ) : (
                    <Input disabled={!urlParams?.editMode} />
                  )}
                </Form.Item>
              )}
            </div>
          ))}
        </Card>

        <Save loading={loading} editMode={urlParams?.editMode} />
      </Form>
    </div>
  );
}
