import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  Row,
  Table,
  Typography,
  Space,
  Select,
  Input,
  notification,
} from "antd";

import * as api from "../../services/api";
import { useLocalStorage } from "../../hooks";
import Config from "../../constants/config";

const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;

function Users() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [accessLevels] = useLocalStorage("user");

  let DefaultUserType;
  if (accessLevels.user_index_single_customer) {
    DefaultUserType = "Customer_user";
  } else {
    DefaultUserType = "Supplier_user";
  }

  const [userType, SetUserType] = useState(DefaultUserType);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const loginAs = (username) => {
    api.loginAs(username).then((res) => {
      window.open(`${Config.customerPanelUrl}auth/loginAs/${res.data.token}`);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Job title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (customer) => customer.name,
    },
    {
      title: "Access Level",
      dataIndex: "roles_permissions",
      key: "roles_permissions",
      render: (roles_permissions) => roles_permissions.name,
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <Link
            to={`/admin/users/detail?username=${data.username}&type=${userType}`}
          >
            View
          </Link>
          {accessLevels.login_as && (
            <Button
              onClick={() => {
                loginAs(data.username);
              }}
            >
              Login as
            </Button>
          )}

          {userType === "Customer_user" ? (
            <>
              {accessLevels.user_soft_delete_customer && (
                <Button
                  onClick={() => {
                    api
                      .softDelete({ type: "user", id: data.username })
                      .then((res) => {
                        notification.success({
                          message: "Success",
                          description: "Item deleted successfully",
                        });
                        getData({
                          page: pagination.current,
                          result: pagination.pageSize,
                          userType: "Customer_user",
                        });
                      });
                  }}
                >
                  Delete
                </Button>
              )}
            </>
          ) : (
            <>
              {accessLevels.user_soft_delete_supplier && (
                <Button
                  onClick={() => {
                    api
                      .softDelete({ type: "user", id: data.username })
                      .then((res) => {
                        notification.success({
                          message: "Success",
                          description: "Item deleted successfully",
                        });
                        getData({
                          page: pagination.current,
                          result: pagination.pageSize,
                          userType: "Supplier_user",
                        });
                      });
                  }}
                >
                  Delete
                </Button>
              )}
            </>
          )}
        </Space>
      ),
    },
  ];

  const getData = async ({
    page,
    result,
    userType,
    filters = null,
    q = null,
  }) => {
    if (userType === undefined) {
      if (accessLevels.user_index_single_customer) {
        userType = "Customer_user";
      } else {
        userType = "Supplier_user";
      }
    }
    return api.getUserList(page, result, userType, filters, q).then((res) => {
      setData(res.data);
      setPagination({
        current: res.meta.current_page,
        pageSize: res.meta.per_page,
        total: res.meta.total,
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getData({ page: pagination.current, result: pagination.pageSize });
    })();
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    getData({
      page: newPagination.current,
      result: newPagination.pageSize,
      userType: userType,
      q: pagination.q,
    });
  };

  const onSearch = (value) => {
    getData({
      page: 1,
      result: pagination.pageSize,
      userType: userType,
      q: value,
    });
  };

  return (
    <>
      <Search
        className="top-page-search"
        placeholder="input search text"
        onSearch={onSearch}
        enterButton
      />

      <h1>Users</h1>
      <div>
        <Row justify="space-between" align="bottom" className="mb-4">
          <Title className="capitalize">User</Title>
          <Button type="primary">
            <Link to={`/admin/users/detail?type=${userType}`}>Add New</Link>
          </Button>
        </Row>
        <Card loading={loading}>
          <Select
            className="w-60"
            defaultValue={userType}
            onChange={(val) => {
              SetUserType(val);
              getData({
                page: 1,
                result: 10,
                userType: val,
              });
            }}
          >
            {accessLevels.user_index_single_customer && (
              <Option value="Customer_user">Customer User</Option>
            )}
            {accessLevels.user_index_single_supplier && (
              <Option value="Supplier_user">Supplier User</Option>
            )}
          </Select>
          <Table
            columns={columns}
            rowKey={(record) => record.email}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </Card>
      </div>
    </>
  );
}

export default Users;
