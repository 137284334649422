import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  Table,
  Typography,
  Space,
  Select,
  Input,
  notification,
} from "antd";

import * as api from "../../services/api";
import { useLocalStorage } from "../../hooks";

const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;

export default function Index() {
  const [accessLevels] = useLocalStorage("user");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  let DefaultUserType;

  if (accessLevels.certificate_hard_delete_hard_restore_recycleBin_admin) {
    DefaultUserType = "certificates";
  } else if (accessLevels.customer_soft_delete_restore_recycleBin_admin) {
    DefaultUserType = "customers";
  } else if (
    accessLevels.manufacture_hard_delete_hard_restore_recycleBin_admin
  ) {
    DefaultUserType = "manufactures";
  } else if (accessLevels.policy_hard_delete_hard_restore_recycleBin_admin) {
    DefaultUserType = "policies";
  } else if (accessLevels.product_hard_delete_hard_restore_recycleBin_admin) {
    DefaultUserType = "products";
  } else if (accessLevels.role_hard_delete_hard_restore_recycleBin_admin) {
    DefaultUserType = "roles";
  } else if (accessLevels.supplier_hard_delete_hard_restore_recycleBin_admin) {
    DefaultUserType = "suppliers";
  } else {
    DefaultUserType = "user";
  }

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
    filter: null,
    q: null,
    type: DefaultUserType,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Deleted By",
      dataIndex: "deleted_by",
      key: "deleted_by",
    },
    {
      title: "Deleted At",
      dataIndex: "deleted_at",
      key: "deleted_at",
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <Button
            onClick={() => {
              if (pagination.type === "products") {
                restore(data.code, data.version);
              } else {
                restore(data.code);
              }
            }}
          >
            Restore
          </Button>
          <Button
            onClick={() => {
              if (pagination.type === "products") {
                hardDelete(data.code, data.version);
              } else {
                hardDelete(data.code);
              }
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const getData = async () => {
    setLoading(true);
    return api.getRecycleBin(pagination).then((res) => {
      setData(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, [pagination]);

  const handleTableChange = (newPagination, filters) => {
    setPagination({
      ...pagination,
      current: newPagination.current,
      page: newPagination.page,
      total: newPagination.total,
      filters: filters,
    });
  };

  const onSearch = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      q: value,
    });
  };

  const restore = (id, version = null) => {
    console.log("🚀 ~ file: recycleBin.js ~ line 111 ~ restore ~ id", id);
    api.restore({ id, version, type: pagination.type }).then((res) => {
      notification.success({
        message: "Success",
        description: "Item Restore successfully",
      });
      getData();
    });
  };

  const hardDelete = (id, version = null) => {
    api.hardDelete({ id, version, type: pagination.type }).then((res) => {
      notification.success({
        message: "Success",
        description: "Item Deleted successfully",
      });
      getData();
    });
  };

  return (
    <>
      <Search
        className="top-page-search"
        placeholder="input search text"
        onSearch={onSearch}
        enterButton
      />

      <h1>Recycle Bin</h1>
      <div>
        <Title className="capitalize">Recycle Bin</Title>
        <Card loading={loading}>
          <Select
            className="w-60"
            defaultValue={pagination.type}
            onChange={(val) => {
              setPagination({
                ...pagination,
                type: val,
              });
            }}
          >
            {accessLevels.certificate_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="certificates">Certificates</Option>
            )}
            {accessLevels.customer_soft_delete_restore_recycleBin_admin && (
              <Option value="customers">Customers</Option>
            )}
            {accessLevels.manufacture_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="manufactures">Manufactures</Option>
            )}
            {accessLevels.policy_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="policies">Policies</Option>
            )}
            {accessLevels.product_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="products">Products</Option>
            )}
            {accessLevels.role_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="roles">Roles</Option>
            )}
            {accessLevels.supplier_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="suppliers">Suppliers</Option>
            )}
            {accessLevels.supplier_user_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="Supplier_user">Supplier Users</Option>
            )}
            {accessLevels.customer_user_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="Customer_user">customer Users</Option>
            )}
            {accessLevels.admin_user_hard_delete_hard_restore_recycleBin_admin && (
              <Option value="Admin_user">Admin Users</Option>
            )}
          </Select>
          <Table
            columns={columns}
            rowKey={(record) => record.email}
            dataSource={data?.data}
            pagination={{
              current: pagination?.current,
              total: data?.meta?.total,
            }}
            loading={loading}
            onChange={handleTableChange}
          />
        </Card>
      </div>
    </>
  );
}
